<template>
    <!-- 全局操作日志 -->
    <div class="operat-log el-content">
        <a-table :pagination="false" :data-source="info.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'操作人',dataIndex:'user.name'},
            {title:'说明',dataIndex:'title'},
            {title:'APPID',dataIndex:'program.program_id'},
            {title:'应用名称',dataIndex:'program.program_name'},
            {title:'操作时间',dataIndex:'create_time'},
        ]">
            <template #name="{ text }">
                <a>{{ text }}</a>
            </template>
        </a-table>

        <div class="pager">
            <a-pagination 
                show-size-changer 
                v-model:current="info.page" 
                v-model:pageSize="info.limit" 
                :total="info.count" 
                @showSizeChange="(p,e)=>{getLog(getLog.page,e)}"
                @change="(e)=>getLog(e,getLog.limit)"
            />
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import setModel from '@/api/saas/set'
export default {
    setup(){
        const state = reactive({
            info:{
                list:[],
                page:1,
                size:10,
                count:0
            }
        })
        getLog(1,10)

        function getLog(page,limit){
            setModel.getSaasLog(page,limit,0,(res)=>state.info = {...res} )
        }

        return{
            ...toRefs(state),
            getLog
        }
    }
}
</script>